.OrderInteractionCard {
    flex-direction: column;
    display: flex;

    .order-interaction-status-bar {
        flex-direction: row;
        display: flex;
        align-items: center;
        padding-bottom: 5px;

        .order-interaction-arrow-icon {
            font-size: 18px;
            color: var(--color_gray_6);
            padding-top: 0;
            padding-bottom: 0;
            margin-right: 5px;
            margin-left: 5px;
        }
    }

    .order-interaction-user {
        label {
            color: var(--color_black_1);
            font-size: var(--fontSize_base);
            font-weight: var(--fontWeight_bold);
            margin-bottom: 0;                
        }
    }

    .order-user-roles {
        padding-bottom: 5px;

        label {
            color: var(--color_gray_6);
            font-size: var(--fontSize_small);
            font-weight: var(--fontWeight_semibold);
            margin: 0;            
        }
    }

    .order-interaction-order-date {
        flex-direction: row;
        display: flex;
        align-items: center;
        
        label {
            font-size: var(--fontSize_h5);
            font-weight: var(--fontWeight_semibold);  
            margin-bottom: 0;                
        }

        .order-interaction-order-date-label {
            color: var(--color_gray_6);
            padding-right: 10px;
        }

        .order-interaction-order-date-value {
            color: var(--color_black_1);
        }        
    }
}
