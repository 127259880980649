@keyframes safariBugFix2 {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.99;
    }
    100% {
        opacity: 1;
    }
}

@keyframes shimmerAnimation {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 800px 0;
    }
}

@mixin shimmer($animationTime: 1s) {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background: linear-gradient(
        to right,
        rgba(var(--color_gray_1--rgb), 1) 0%,
        rgba(var(--color_gray_1--rgb), 0.2) 20%,
        rgba(var(--color_gray_1--rgb), 1) 100%
    );
    animation: $animationTime forwards linear infinite shimmerAnimation;
}
