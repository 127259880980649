.Toastify__toast-container .Toastify__toast {
  border-radius: 16px;
  box-shadow: 0 4px 10px 0 rgba(var(--color_black_0--rgb), 0.3), 0 2px 12px 0 rgba(var(--color_black_0--rgb),0.2);

  & .ActionButton {
    text-align: right;

    & .ActionButton__button {
      height: auto;
      padding: 5px 10px;
      font-size: 12px;
    }
  }
}
.ToastNotificationWrapper {
  &__title,
  &__message {
    color: var(color_black_1);
  }

  &__title {
    font-weight: 600;
  }

  &__message {
    &:has(a) {
      text-align: right;
    }
    font-weight: 500;
    & a {
      font-weight: 600;
    }
  }
}