#BetaTest_Content {
  z-index: 999;
  position: absolute;
  float: left;
  border-radius: 20px;
  background-color: var(--color_white_1);
  width: 380px;
  max-height: 350px;
  box-shadow: 2px 2px 5px var(--color_gray_6);
  display: block;
  top: 60px;
  padding: 1em;
  right: 50px;

  .visible {
    display: block;
  }

  .invisible {
    display: none;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray_6);
  }

  .hint {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--color_gray_1);
    border-radius: 10px;
    padding: 1em;
    margin-top: 0.5em;

    .betaIcon {
      width: 40px;
      height: 40px;
      background-color: var(--color_gray_2);
      padding-top: 0.2em;

      svg {
        width: 20px;
        height: 20px;
        fill: var(--gray_6);
      }
    }

    &__text {
      width: 80%;
      color: var(--gray_6);
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2em;
    max-height: 200px;
    overflow-y: auto;

    &__item {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .custom-control {
        right: 0;
        left: auto;
        position: relative;
      }
    }
  }
}

#BetaTest_Modal {
  width: 520px;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modal-content {
    padding: 0;
    border-radius: 20px;

    .modal-header {
      border-radius: 20px 20px 0 0;
      padding: 24px;
      width: 100%;
      position: relative;
      background-color: var(--color_yellow_1);
      display: flex;
      justify-content: center;

      .betaIcon {
        width: 108px;
        height: 108px;
        background-color: var(--color_yellow_5);

        svg {
          width: 64px;
          height: 64px;
          fill: var(--color_yellow_7) !important;
        }
      }
    }

    .modal-body {
      padding: 1em;
      text-align: center;

      .modal-title {
        font-weight: 600;
        font-size: 30px;
      }

      .subtitle {
        font-weight: 500;
        font-size: 15px;
      }

      .hint {
        font-weight: 500;
        font-size: 13px;
        padding-left: 30px;
        padding-right: 30px;
        color: var(--color_gray_6);
        margin-bottom: 0px;

        .iconHint {
          width: 24px;
          height: 24px;
          display: inline-block;

          svg {
            width: 20px;
            height: 20px;
            fill: var(--color_gray_6);
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      padding-bottom: 1em;

      .confirmBtn {
        width: 70px;
        border-radius: 5px;
        color: var(--color_primary_1);
        background-color: var(--color_primary_1);
      }
    }
  }
}

.betaIcon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5em;
}