.NoteInteractionCard {
    flex-direction: column;
    display: flex;

    .note-interaction-user {
        label {
            color: var(--color_black_1);
            font-size: var(--fontSize_base);
            font-weight: var(--fontWeight_bold);
            margin-bottom: 0;                
        }
    }

    .note-interaction-notes {
        label {
            color: var(--color_gray_6);
            font-size: var(--fontSize_h5);
            font-weight: var(--fontWeight_semibold);  
            margin: 0;            
        }
    }
}