@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";

@import "styles/master";

.App {
    color: var(--fontColor_default);
    background-color: var(--backGroundColor_default);
    font-weight: var(--fontWeight_normal);
    font-size: var(--fontSize_small);
    display: grid;
    grid-template-columns: max-content;
    grid-template-rows: max-content;
    grid-template-areas:
        "header header header header"
        "sidebar body body body";
    grid-gap: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#AppToast {
    position: absolute;
}

.AppHeader {
    grid-area: header;
}

.AppSidebar {
    grid-area: sidebar;

    >.container-sidebar {
        width: 58px;
        transition: all 100ms ease-in;
    }

    >.container-sidebar-expanded {
        width: 232px;
        transition: all 100ms ease-in;
    }

}

.AppBody {
    grid-area: body;
    position: relative;
    overflow: auto;

    >* {
        height: 100%;
    }

    &.PanelOverlay {
        padding-left: 64px;
    }

    &--no-overflow {
        overflow: unset;
        height: 100vh;
    }
}

.SpringPage {
    width: 100%;
    height: 100%;
}

.flex-container {
    display: flex;
    background: var(--color_gray_1);
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.pageOverlay {
    min-height: 100vh;
    min-width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media screen and (max-width: 480px) {
    .App {
        height: 100%;
        overflow: hidden;
        overscroll-behavior-y: none;
    }
}