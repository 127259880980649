@import "../../styles/master";

.InputSearch {
    $b: &;
    font-size: 14px;
    position: relative;

    > * {
        position: absolute;
    }

    #{ $b }__input-container {
        width: 100%;
        height: 100%;
        position: relative;
        > * {
            position: absolute;
            top: 0;
        }
        input {
            @include input(0.8em);
            padding: 0.25em 0.5em;
            padding-left: 2em;
        }
        input:disabled {
            background-color: var(--color_white_1);;
        }
    }
    #{ $b }__icon {
        width: 1em;
        height: 1em;
        pointer-events: none;
        color: var(--color_gray_2);
        top: 50%;
        left: 0.75em;
        transform: translate(0, -50%);
    }

    #{ $b }__clear {
        right: 0.5em;
        width: 1.1428em;
        height: 1.1428em;
        padding: 3px;
        background-color: var(--color_gray_2);
        border-radius: 50%;
        stroke: var(--color_gray_6);

        cursor: pointer;
        top: 50%;
        transform: translate(0, -50%);
    }

    #{ $b }__options {
        top: calc(100% + 11px);
        background-color: var(--color_white_1);
        border-radius: 4px;
        border: 1px solid var(--color_gray_3);
        box-shadow: 2px 4px 8px 2px rgba(var(--color_gray_7--rgb), 0.2);
        width: 100%;
        z-index: 1000;

        #{ $b }__options-container {
            max-height: 350px;
            overflow-y: auto;

            #{ $b }__options-group {
                color: var(--color_black_1);
    
                >*::first-child,>*::last-child {
                    padding: 1.1428em;
                }

                &:not(:first-child) {
                    border-top: 1px solid var(--color_gray_2);
                }
    
                #{ $b }__option-group-label {
                    font-weight: 600;
                    font-size: 0.8571em;
                    margin-bottom: 0;
                    position: sticky;
                    top: 0;
                    width: 100%;
                    background-color: var(--color_white_1);
                    padding: 1.1428em 1.5em 0.5714em 1.5em;
                    box-shadow: 1px 2px 1px 1px rgba(var(--color_green_1--rgb), 0.1);

                    #{ $b }__option-group-clear {
                        color: var(--color_orange_5);
                        float: right;
                        cursor: pointer;
                    }
                }
                #{ $b }__option {
                    border-bottom: 1px solid var(--color_gray_3);
                    font-weight: 500;
                    font-size: 1em;
                    cursor: pointer;
                    padding: 1em 1.1428em;
                    &:hover {
                        background-color: var(--color_gray_1);   
                    }
                    #{ $b }__option-type {
                        color: var(--color_gray_3);   
                        font-weight: 600;
                    }
                    #{ $b }__option-detail-name {
                        margin-left: 15px;
                        font-weight: 600;
                        font-size: 12px;
                        color:var(--color_black_1);
                    }
                    #{ $b }__option-separator {
                        margin-left: 5px;
                        margin-top : -3px;
                        color: var(--color_gray_3);
                    }
                    #{ $b }__option-detail-code {
                        margin-left: 15px;
                        font-weight: 500;
                        font-size: 12px;
                        color: var(--color_gray_3);
                    }
                    #{ $b }__option-detail-id {
                        margin-left: 5px;
                        font-weight: 500;
                        font-size: 12px;
                        color: var(--color_gray_3);
                    }
                    #{ $b }__option-detail-address {
                        margin-left: 15px;
                        font-weight: 500;
                        font-size: 12px;
                        color: var(--color_gray_4);
                    }
                    
                }
            }
        }
    }
    
    #{ $b }__options--empty {
        width: 100%;
        padding: 1.1428em;
        font-weight: 500;
        color: var(--color_black_1);
        overflow: hidden;
    }

    #{ $b }__more {
        cursor: pointer;
        padding: 1.1785em 1.1428em 1.1428em 1.1428em; 
        font-size: 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--color_gray_2);

        #{ $b }__more-message {
            display: flex;
            align-items: center;

            font-weight: 500;
            color: 1px solid var(--color_black_1);

            >*:not(:first-child) {
                padding-left: 0.5em;
                padding-right: 0.5em;
            }

            #{ $b }__more-icon {
                width: 2em;
                height: 2em;
                aspect-ratio: 1;
                stroke: var(--color_black_1);
            }

            #{ $b }__more-cta {
                color: var(--color_gray_3);   
                font-size: 1em;
                font-weight: 600;
            }   
        } 

    }
    .highlight {
        color: var(--color_primary);
    }
}