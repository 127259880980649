@import "../../styles/buttons";

// .AppBody {
//     overflow: hidden !important;
// }

// .react-bootstrap-table {
//     height: calc(100% - 196px);
//     overflow-y: scroll;
// }
:root {
    --filter-visible: 0;
}

$filter-width: 310px;
$filter-border-right-width: 1px;
$filter-horizontal-padding: 0px;
$filter-width-with-border: calc(#{$filter-width} + #{$filter-border-right-width} + #{$filter-horizontal-padding});

.GenericListFilter {
    font-family: 'Montserrat';
    font-style: normal;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    overflow-x: hidden;

    &.open {
        transition: all 0.4s;
        width: $filter-width-with-border;
        min-width: $filter-width-with-border;
    }

    .frame {
        width: 100%;
        padding: 16px;
        gap: 3px;
        display: flex;
        flex-direction: column;
        border-style: solid;
        border-color: var(--color_gray_2);
        border-width: 0px 1px 0px 0px;

        .header {
            margin-bottom: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: var(--color_black_1);
            }

            .count {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                align-items: center;
                color: var(--color_gray_4);
            }

            .clear {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: var(--color_orange_4);

            }
        }

        .filters {
            .toggle {
                display: flex;
                width: 100%;
                margin: 0px;
                justify-content: space-between;
            }

            .accordion {
                p {
                    font-size: 14px;
                    margin-left: -15px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }
            }

            .type-date {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                border-radius: 7px;
            }

            .type-active {
                border: var(--color_primary);
                border-radius: 7px;
                position: relative;
            }

            .type-inactive {
                border: var(--color_gray_1);
                color: var(--color_gray_4);
                background-color: var(--color_gray_1);
                border: none;
            }
        }
    }

    .filter-clear {
        font-weight: var(--fontWeight_semibold);
        font-size: var(--fontSize_small);
        color: var(--color_orange_5);

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}