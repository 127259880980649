.StatusFilter {
    text-overflow: ellipsis;
    white-space: nowrap;

    div {
        flex-wrap: nowrap;
        align-items: center !important;
        margin: 0px;

    }

    .Mui-checked{
        color: var(--color_primary) !important;
    }

    label {
        margin-bottom: -5px;
        white-space: nowrap;
        overflow: hidden;

        .color-badge {
            width: 200px;
            border-radius: 0px;
            height: 17px;
        }
    }
}