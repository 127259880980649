$semanticValueList: 
    "&.INPROGRESS, &.RECEIVED, &.PUBLISHED" rgba(var(--color_primary--rgb), 0.2) var(--color_primary),
    "&.PLANNED" rgba(var(--color_purple_4--rgb), 0.2) var(--color_purple_4),
    "&.UNKNOWN, &.PAUSED, &.NEUTRAL" rgba(var(--color_gray_3--rgb), 0.2) var(--color-gray_3),
    "&.CANCELLED, &.ERROR, &.REJECTED, &.REJEC, &.BLOCKED, &.EXPIRED, &.NOTGOOD" rgba(var(--color_red_5--rgb), 0.2) var(--color_red_5),
    "&.FINALIZED, &.SUBMITTED, &.FINALIZING, &.GOOD, &.SETTLED, &.APPRO, &.SENT"  rgba(var(--color_green_5--rgb), 0.2) var(--color_green_5),
    "&.PENDING, &.DRAFT, &.SUSPENDED" rgba(var(--color_yellow_5--rgb), 0.2) var(--color_yellow_5);

.color-badge {
    background-color: var(--color_white_1);
    border-radius: 4px;
    width: 120px;
    height: 21px;

    .badge-container {
        width: 100%;
        border-radius: 4px;

        .badge-text {
            font-weight: var(--fontWeight_semibold);
            font-size: var(--fontSize_p);
            margin: 0;
        }

        @each $semanticValue, $containerBgColor, $textColor in $semanticValueList {
            #{$semanticValue} {
                background-color: $containerBgColor;

                .badge-text {
                    color: $textColor;
                }
            }
        }
    }
}

.color-badge-status {
    @each $semanticValue, $containerBgColor, $textColor in $semanticValueList {
        #{$semanticValue} {
            .badge-background {
                background-color: $containerBgColor;
            }

            .badge-background-primary {
                background-color: $textColor;
            }

            .badge-text {
                color: $textColor;
            }
        }
    }

    .badge-default-text {
        color: white;
    }
}