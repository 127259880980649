.PriceAgreementInteractionCard {
    flex-direction: column;
    display: flex;

    .price-agreement-name-and-status {
        flex-direction: row;
        padding-bottom: 4;
        align-items: center;
        display: flex;
        justify-content: space-between;
        
        .price-agreement-interaction-user {
            label {
                color: var(--color_black_1);
                font-size: var(--fontSize_base);
                font-weight: var(--fontWeight_bold);
                margin-bottom: 0;                
            }
        }
    }

    .price-agreement-extra-field {
        flex-direction: row;
        display: flex;
        align-items: center;
        
        label {
            font-size: var(--fontSize_h5);
            font-weight: var(--fontWeight_semibold);  
            margin-bottom: 0;                
        }

        .price-agreement-extra-field-label {
            color: var(--color_gray_6);
            padding-right: 10px;
        }

        .price-agreement-extra-field-value {
            color: var(--color_black_1);
        }        
    }
}
