.TaskInteractionCard-name {
    label {
        color: var(--color_black_1);
        font-size: var(--fontSize_base);
        font-weight: var(--fontWeight_bold);
        margin: 0;            
    }
}

.TaskInteractionCard-classification {
    padding-bottom: 5px;
    
    label {
        color: var(--color_gray_6);
        font-size: var(--fontSize_small);
        font-weight: var(--fontWeight_semibold);
        margin: 0;            
    }
}