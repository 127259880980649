@import "./../../styles/master";
@import "./../../styles/animations";

.SpringGrid {
  .table {
    width: 100%;
    table-layout: auto;
  }
  &__spinner {
    position: fixed;
    bottom: 1.5em;
    right: 3em;
    &--position-initial {
      position: initial;
    }
  }

  .SpringGridHeader > tr {
    height: 66px;
    border: none !important;
  }

  .SpringGridRows {
    flex: 1;
    height: 34px;

    .Actions {
      text-align: right;
      > *:not(:first-child) {
        margin-left: 14px;
      }
      > *:last-child {
        margin-right: 14px;
      }
      svg,
      .spinner {
        width: 24px;
        height: 24px;
      }
    }

    .resizing {
      border-right: 1px solid var(--color_primary);
    }
  }

  .SpringGridRows > td {
    height: inherit;
  }

  .SpringGridHeader > tr > th {
    background-color: var(--color_white_1);
    color: var(--fontColor_gridHeader) !important;
    border: none;
    border-bottom: 1px solid var(--color_gray_1) !important;
    border-top: 1px solid var(--color_gray_1) !important;
    font-size: var(--fontSize_p);
    font-weight: var(--fontWeight_bold);
    line-height: var(--lineHeight_normal);
    outline: none;
    padding: 20px 30px;
    position: sticky;
    box-shadow: inset 0 0 0 transparent, inset 0 -2px 0 var(--color_gray_1);
    top: 0;
    z-index: 1;
    &[aria-label~="asc"],
    &[aria-label~="desc"] {
      color: var(--color_primary) !important;
      opacity: 1;
    }

    &[aria-label~="desc"] span.caret-4-desc::before {
      background-color: var(--color_primary);
      -webkit-mask-image: url("./../../assets/icons/svg/arrow-up.svg");
      mask-image: url("./../../assets/icons/svg/arrow-up.svg");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-position: center;
      mask-position: center;
    }

    &[aria-label~="asc"] span.caret-4-asc::before {
      background-color: var(--color_primary);
      -webkit-mask-image: url("./../../assets/icons/svg/arrow-down.svg");
      mask-image: url("./../../assets/icons/svg/arrow-down.svg");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-position: center;
      mask-position: center;
    }

    span {
      font-size: 32px;
      margin-left: 10px;
      font-weight: 300;
      vertical-align: sub;
    }

    span::before,
    span::after {
      content: "_";
      color: transparent;
      opacity: 1;
      background-position: center center;
      background-size: 150% 150%;
      background-repeat: no-repeat;
    }
  }

  .SpringGridRows > td {
    font-size: var(--fontSize_p);
    font-weight: 500;
    line-height: var(--lineHeight_normal);
    color: var(--color_black_1);
    border-bottom: 1px solid var(--color_gray_1);
    padding: 0 30px;
    vertical-align: middle;
    height: 62px;
  }

  .checkboxList {
    .selection-input-4:checked {
      color: var(--color_primary);
      background-color: var(--color_primary);
      border-color: var(--color_primary);
    }
  }

  .page-item.active .page-link {
    background-color: var(--color_primary);
    border-color: var(--color_primary);
    color: var(--color_white_1);
  }

  .page-item .page-link {
    background-color: var(--color_white_1);
    color: var(--color_gray_3);
    border: 1px solid var(--color_gray_3);
  }

  &--row-hoverable {
    .SpringGridRows:visited {
      background: unset;
    }
    .SpringGridRows:hover {
      cursor: pointer;
      background: rgba(var(--color_primary--rgb), 0.2);
    }
  }

  // Loading
  &--loading {
    table {
      pointer-events: none;
      border-collapse: separate;
      border-spacing: 0 10px;
    }

    .SpringGridHeader > tr > th {
      padding: 20px 8px;
    }

    .SpringGridRows {
      @include shimmer;
    }

    .SpringGridRows > td {
      color: transparent;
      border: none;

      &:not(:first-child),
      &:not(:last-child) {
        border-radius: 0;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:nth-last-child(2) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .SpringGridRows > td:last-child {
      padding: 0;
      background-color: var(--color_white_1);
    }

    .SpringGridRows > td:last-child .Actions {
      height: 100%;
      margin-left: 1rem;
      border-radius: 8px;
      @include shimmer(4s);
    }

    .SpringGridRows > td svg {
      display: none;
    }
  }

  // Styles overwrite for react-bootstrap-table
  .react-bootstrap-table-pagination {
    width: 100%;

    .dropdown-menu {
      top: auto;
      bottom: 2rem;
    }
  }

  .selection-input-4 {
    margin: -4px;
    color: var(--color_primary);
    background-color: var(--color_primary);
    border-color: var(--color_primary);
  }

  // Aux classes
  .SpringGridHide {
    display: none !important;
  }
}
