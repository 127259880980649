::-webkit-scrollbar {
    width: 12px;
    height: 16px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(var(--color_black_0--rgb), 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(var(--color_black_0--rgb), 0.15);
    box-shadow: inset -1px -1px 0px rgba(var(--color_black_0--rgb), 0.05), inset 1px 1px 0px rgba(var(--color_black_0--rgb), 0.05);
}
::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}
