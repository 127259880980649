.LoadingData {
  display: block;
  text-align: center;
  font-weight: 600;
  > * {
    margin: 10px;
  }
  &__icon {
    width: 24px;
    height: 24px;
    stroke: var(--color_gray_3);
  }
  &__message {
    font-size: var(--fontSize_p);
    color: var(--color_gray_3);
  }
}
.spinner{
  color: var(--color_primary)!important;
}
