.Icon {
    color: var(--color_primary);
    user-select: none;
    &--disabled {
        cursor: default !important;
    }

    .no-margin {
        margin: 0;
    }
}
