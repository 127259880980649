$buttonHeight: 40px;
.ActionButton {
    height: $buttonHeight;

    &__button.btn.btn-main-action,
    &__button.btn.btn-main-action:hover {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &__spinner.spinner-border {
        color: var(--color_primary);
        width: calc(#{$buttonHeight} * 0.6);
        height: calc(#{$buttonHeight} * 0.6);
    }

    &--loading {
        .ActionButton__button.btn.btn-main-action,
        .ActionButton__button.btn.btn-main-action:hover {
            background-color: transparent;
            width: auto;
        }
    }
    &__button--no-label {
        padding: 11px;
        width: 40px;
        height: 40px;
    }

    &__label{
        display: inline-block;
        align-items: center;
    }
    &__reverse{
        transform: rotate(180deg);
    }
}
