.GenericInteractionCard {
    border-bottom: 1px solid var(--color_gray_2);
    margin-bottom: 5px;
    padding-bottom: 10px;

    .standard-card {
        flex-direction: row;
        padding-bottom: 4;
        align-items: center;
        display: flex;
        padding-bottom: 5px;

        .interaction-icon {
            width: 24px;
            height: 24px;
            padding: 4px;
            border-radius: 6px; 
            fill: var(--color_white_1);
            background-color: var(--color_primary);
            color: var(--color_white_1);        
        }
       
        .interaction-header {
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            display: flex;

            .interaction-title {
                padding-left: 5px;
                flex-direction: column;
                display: flex;

                .interaction-code {
                    color: var(--color_gray_6);
                    font-size: var(--fontSize_x_small);
                    font-weight: var(--fontWeight_semibold);
                    margin: 0;
                    padding-right: 5px;
                }

                .interaction-type {
                    text-transform: uppercase;
                    color: var(--color_gray_6);
                    font-size: var(--fontSize_h5);
                    font-weight: var(--fontWeight_semibold);
                    margin: 0;
                }    
            }  

            .interaction-time {
                color: var(--color_gray_6);
                font-size: var(--fontSize_small);
                font-weight: var(--fontWeight_semibold);
                margin: 0;
            } 

        
        }
    }

    .interaction-extra-container {
        flex-direction: column;
        display: flex;

        .interaction-status-bar {
            flex-direction: row;
            display: flex;
            align-items: center;

            .interaction-arrow-icon {
                font-size: 18px;
                color: var(--color_gray_6);
                padding-top: 0;
                padding-bottom: 0;
                margin-right: 5px;
                margin-left: 5px;
            }
        }

        .interaction-user-date {
            flex-direction: row;
            display: flex;
            align-items: center;
            
            label {
                color: var(--color_black_1);
                font-size: var(--fontSize_h5);
                font-weight: var(--fontWeight_semibold);
                margin-bottom: 0;                
            }

            .interaction-dot-icon {
                font-size: 5px;
                color: var(--color_gray_6);
                margin-right: 5px;
                margin-left: 5px;
            }
        }
    }

}

