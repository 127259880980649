.SpringGridResizable {
    width: "100%";

    .resizebar {
        display: block;
        position: absolute;
        cursor: col-resize;
        width: 7px;
        right: 0;
        top: 25%;
        z-index: 1;
        border-right: 2px solid transparent;
        height: 50%;
        border-color: var(--color_gray_2);
    }

    .resizebar:hover,
    .active {
        border-color: var(--color_primary);
        height: 100%;
        top: 0;
    }
}